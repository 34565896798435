/* snippet from: https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

* {
  font-family: inherit;
}

.react-codemirror2 {
  width: 100%;
  flex-grow: 1;
}

.CodeMirror {
  height: 100%;
  background: #fdf6e3; /* solarized.light.bg */
}

.repl-body ::selection {
  background: rgba(7, 54, 66, 0.99);
}